.blog {
  &__single-post {
    box-shadow: 0px 1px 15px 0px $color-shadow;
    border-radius: 5px;
    overflow: hidden;
    &:hover {
      .blog__single-post__image {
        img {
          transform: scale(1.1);
        }
      }
    }
    &__image {
      overflow: hidden;
      img {
        transition: 0.3s;
      }
    }
    &__body {
      padding: 30px;
      background-color: $color-light;
      @include respond(laptop) {
        padding: 10px;
        padding: 20px;
      }
    }
    &__content {
      padding-bottom: 15px;
      h2 {
        a {
          color: $color-primary;
          &:hover {
            color: $color-secondary;
          }
        }
      }
    }
    .blog-metainfo {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      li {
        display: flex;
        gap: 5px;
        align-items: center;
        a:hover {
          color: $color-primary;
        }
        svg {
          color: $color-primary;
        }
      }
    }
    &__meta {
      display: flex;
      gap: 5px;
      align-items: center;
      border-top: 1px solid $color-border;
      padding-top: 15px;
      svg {
        color: $color-primary;
      }
    }
  }
}
