.achievement {
  &__item {
    box-shadow: 5px 5px $color-secondary;
    background-color: $bg-one;
    &__image {
      height: 100%;
      object-fit: cover;
    }
  }

  .card {
    box-shadow: 2px 0px 15px 5px $color-shadow;
    border: none;
    &:hover {
      box-shadow: 4px 0px 25px 10px $color-shadow;
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    padding: 30px 10px 10px 0px;
    &__icon {
      img {
        position: absolute;
        opacity: 0.3;
        top: 0;
        right: 0;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.bg-one {
  .achievement__item {
    background-color: $color-light;
  }
  .achievement__item img {
    height: 100%;
    object-fit: cover; 
  }
  .achievement__item .row{
    height:100%;
  }
  .achievement__item .achievement__content__icon img {
    height: auto;
  }
}